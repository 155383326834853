import request from '@/utils/request'

// 首页数据概览
export function data_general(data) {
  return request({
    url: '/merchant/data/general',
    method: 'get',
    params: data
  })
}

// 业务部数据  运营数据
export function market_data(data) {
  return request({
    url: '/merchant/data/marketData',
    method: 'get',
    params: data
  })
}