<style scoped>
</style>
<template>
  <div class="pb20">
    <a-card class="w-100">
      <a-descriptions title="工作台" bordered>
        <a-descriptions-item label="申请用户">
          <router-link to="/user/apply">
            <span>{{ indexData.user_apply }}</span>
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="旅游报名">
          <router-link to="/user/travelRegistration">
            <span>{{ indexData.user_travel }}</span>
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="福利礼包">
          <router-link to="/user/welfarePackage">
            <span>{{ indexData.user_welfare }}</span>
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="缺货商品">
          <router-link to="/goods/index">
            <span>{{ indexData.goods_lack }}</span>
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="提现审核">
          <router-link to="/finance/withdrawal">
            <span>{{ indexData.withdraw_apply }}</span>
          </router-link>
        </a-descriptions-item>
        <a-descriptions-item label="待开发票">
          <router-link to="/invoice/index">
            <span>{{ indexData.invoice_apply }}</span>
          </router-link>
        </a-descriptions-item>
      </a-descriptions>
    </a-card>

    <a-row :gutter="30">
      <!-- 热销的商品 -->
      <a-col class="mt20" :xs="12" :sm="12" :lg="12">
        <a-card class="w-100">
          <div class="w-100" id="hotGoods" style="height: 500px"></div>
        </a-card>
      </a-col>

      <a-col class="mt20" :xs="12" :sm="12" :lg="12">
        <a-card class="w-100">
          <div class="w-100" id="line" style="height: 500px"></div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { data_general } from "@/api/data";
export default {
  data() {
    return {
      indexData: {
        user_apply: 0,
        user_travel: 0,
        user_welfare: 0,
        goods_lack: 0,
        withdraw_apply: 0,
        invoice_apply: 0,
        recent_user: [],
        recent_goods: [], //近期商品
      },
    };
  },
  created() {},
  mounted() {
    data_general()
      .then((res) => {
        // 工作台,用户数据,订单数据 赋值
        for (let key in res) {
          this.$set(this.indexData, key, res[key]);
        }
        this.barInit(res.recent_user, "line", "line");
        this.barInit(res.recent_goods ? res.recent_goods : [], "hotGoods");
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {
    barInit(data, id, type = "bar") {
      let xData = [];
      let yData = [];
      if (id == "hotGoods") {
        for (let i = 0; i < data.length; i++) {
          xData.push(data[i].goods_name);
          yData.push(data[i].num);
        }
      } else {
        for (const item in data) {
          xData.push(item);
          yData.push(data[item]);
        }
      }
      let series = {
        name: id == "hotGoods" ? "数量" : "金额",
        type: type,
        label: { show: true, position: "top" },
        data: yData,
      };

      var mycahrt = echarts.init(document.getElementById(id)); //显示 id main  dark 主题
      var option = {
        backgroundColor: "#fff", //背景
        title: {
          text: id == "hotGoods" ? "热销的商品" : "近期用户增长趋势", //      //标题,可选
        },
        tooltip: {},
        xAxis: {
          splitLine: { show: false }, //是否显示网格线，默认不显示，可选
          data: xData,
        },
        yAxis: { splitLine: { show: false } }, //默认显示，可选
        series: [series],
      };
      mycahrt.setOption(option); //生成图表
    },
  },
};
</script>
